<script setup lang="ts">
import { useElementSize } from '@vueuse/core';
import ListSkeletonLoader from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ListSkeletonLoader.vue';
import ProductListEmptyState from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListEmptyState.vue';
import ProductListItem from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListItem.vue';
import ProductSortDropdown from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductSortDropdown.vue';
import CategoryList from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/CategoryList.vue';
import ProductListPagination from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ProductListPagination.vue';
import { objectToCssVariablesDeprecated } from '@zyro-inc/site-modules/utils/objectToCssVariablesDeprecated';
import { DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH } from '@zyro-inc/site-modules/constants/defaultStyles';
import SiteElementSearchBar from '@zyro-inc/site-modules/components/SiteElementSearchBar.vue';
import {
	getFormattedBookingDuration,
	isProductPriceRangeShown,
	getFullProductQuantity,
	getScrollToTopTriggerProductIndex,
	DEFAULT_COLUMN_COUNT_MOBILE,
	DEFAULT_COLUMN_COUNT_DESKTOP,
} from '@zyro-inc/site-modules/components/blocks/ecommerce/utils';
import { getLowestPriceVariant } from '@zyro-inc/site-modules/utils/ecommerce/productUtils';
import {
	DATA_ATTRIBUTE_ANIMATION_STATE,
	DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE,
} from '@zyro-inc/site-modules/constants/siteModulesConstants';

import {
	ref,
	computed,
	StyleValue,
	watch,
	onMounted,
	toRef,
} from 'vue';
import {
	EcommerceCollection,
	EcommerceProduct,
	EcommerceProductSorting,
	EcommerceProductSearch,
	EcommerceProductVariantQuantity,
	EcommerceProductImage,
	EcommerceVariantPrice,
	SiteElementSearchItem,
	EcommerceProductType,
} from '@zyro-inc/site-modules/types';
import {
	SortingOptions,
	ImageHoverEffect,
	EcommerceProductListScrollBehaviour,
	SiteEcommerceSortingValue,
	ImageRatioOption,
} from '@hostinger/builder-schema-validator/schema/schemaTypes';
import { ECOMMERCE_SORTING_VALUES } from '@zyro-inc/site-modules/constants/ecommerce';
import ScrollToTop from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ScrollToTop.vue';
import ScrollToTopThresholdMarker from '@zyro-inc/site-modules/components/blocks/ecommerce/-partials/ScrollToTopThresholdMarker.vue';

const MAX_WIDTH_CATEGORY_LIST = 200;

interface Props {
	blockId: string;
	pageCount: number;
	currentPage: number;
	products: Array<EcommerceProduct> | [];
	totalProductCount: number;
	legacyProductPages?: Record<string, any>;
	blockStyle?: Record<string, string>;
	textColorVars?: Record<string, string>;
	isProductListShown: boolean;
	isLoading?: boolean;
	isCategoriesLoaded: boolean;
	isEager?: boolean;
	columnCount?: number;
	translations: Record<string, string>;
	productCategoryId?: string;
	isButtonEnabled?: boolean;
	buttonDisplay?: string;
	buttonText?: string;
	buttonStyle?: Record<string, string>;
	buttonType?: string;
	buttonBorderWidth?: number;
	ribbonStyle?: Record<string, string>;
	isProductListItemLinkDisabled?: boolean;
	siteId: string;
	variantsQuantity: Array<EcommerceProductVariantQuantity>;
	productSorting: EcommerceProductSorting;
	productSearch: EcommerceProductSearch;
	sorting?: SiteEcommerceSortingValue;
	imageRatio?: ImageRatioOption;
	categories?: EcommerceCollection[];
	isCategoryListEnabled?: boolean;
	backgroundColor?: string;
	scrollToTopBackgroundColor?: string;
	isMobileView?: boolean;
	imageHoverEffect?: ImageHoverEffect;
	isCategoryItemLinkDisabled?: boolean;
	isFullWidth?: boolean;
	isTotalProductCountShown?: boolean;
	columnGap?: number;
	rowGap?: number;
	isListCentered?: boolean;
	isButtonFullWidth?: boolean;
	scrollBehaviour: EcommerceProductListScrollBehaviour;
	isCartVisible?: boolean;
	canAddToCart?: (product: EcommerceProduct, productVariantId?: string) => boolean;
	isDynamicProductPageEnabled?: boolean;
	isWhatsAppEnabled?: boolean;
	isInEditor?: boolean;
	isPreviewMode?: boolean;
	isScrollToTopEnabled?: boolean,
	scrollToTopStyle?: {
		backgroundColor: string,
		contrastBackgroundColor: string
	};
	isSearchResultsLoading?: boolean;
	searchResults?: Array<SiteElementSearchItem> | null;
	searchTerm?: string;
}

const props = withDefaults(defineProps<Props>(), {
	isProductListShown: true,
	buttonType: 'primary',
	legacyProductPages: () => ({}),
	blockStyle: () => ({}),
	textColorVars: () => ({}),
	productCategoryId: '',
	buttonDisplay: '',
	buttonText: '',
	buttonStyle: () => ({}),
	buttonBorderWidth: 0,
	ribbonStyle: () => ({}),
	sorting: ECOMMERCE_SORTING_VALUES[SortingOptions.DEFAULT],
	productSorting: () => ({
		enabled: false,
	}),
	productSearch: () => ({
		isEnabled: false,
		placeholderText: '',
		noResultsText: '',
	}),
	imageRatio: ImageRatioOption.COVER,
	isCategoryListEnabled: false,
	backgroundColor: 'var(--color-light)',
	isMobileView: false,
	imageHoverEffect: ImageHoverEffect.NO_EFFECT,
	columnGap: 24,
	rowGap: undefined,
	isCartVisible: true,
	canAddToCart: () => true,
	isDynamicProductPageEnabled: false,
	isSearchResultsLoading: false,
	searchResults: null,
	searchTerm: '',
});

const emit = defineEmits<{
	'product-click': [EcommerceProduct],
	'button-click': [EcommerceProduct],
	'page-changed': [number],
	'sort-changed': [Event],
	'category-click': [string],
	'is-dropdown-open': [boolean]
	'update:search-term': [string]
}>();

const isMobileView = toRef(() => props.isMobileView);
const isAnimationActive = ref(false);
const blockProductListRef = ref<HTMLElement | null>(null);
const productListContentContainerRef = ref<HTMLElement | null>(null);
const blockListWidth = ref(0);
const resizeObserver = ref<ResizeObserver>(null as unknown as ResizeObserver);
const isScrollToTopVisible = ref(false);

const { height: productListHeightRef } = useElementSize(productListContentContainerRef);

const columnCountDefaultValue = computed(() => (isMobileView.value ? DEFAULT_COLUMN_COUNT_MOBILE : DEFAULT_COLUMN_COUNT_DESKTOP));
const columnCountValue = computed(() => props.columnCount || columnCountDefaultValue.value);
const customAttributes = computed(() => ({
	[DATA_ATTRIBUTE_ANIMATION_STATE]: isAnimationActive.value ? DATA_ATTRIBUTE_ANIMATION_STATE_ACTIVE : null,
}));
const emptyPageMessage = computed(() => props.translations.onlineStoreNoProducts || 'No publicly visible products');
const textAlign = computed(() => props.blockStyle?.textAlign);
const imageWidth = computed(() => {
	const totalGapsWidth = (columnCountValue.value - 1) * props.columnGap;
	const categoryListWidth = props.isCategoryListEnabled ? MAX_WIDTH_CATEGORY_LIST : 0;
	const blockWidth = blockListWidth.value || ((typeof window !== 'undefined') && window?.innerWidth) || DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH;
	const contentWidth = props.isFullWidth ? blockWidth : DEFAULT_ECOMMERCE_PRODUCT_CONTENT_WIDTH;
	const listWidth = contentWidth - totalGapsWidth - categoryListWidth;

	return Math.floor(listWidth / columnCountValue.value);
});
const imageAspectRatioValues = computed<{ width: number, height: number }>(() => {
	if (props.imageRatio === ImageRatioOption.LANDSCAPE) {
		return {
			width: 16,
			height: 9,
		};
	}

	if (props.imageRatio === ImageRatioOption.PORTRAIT) {
		return {
			width: 4,
			height: 5,
		};
	}

	return {
		width: 1,
		height: 1,
	};
});
const imageHeight = computed(() => {
	const imageHeightValue = (imageWidth.value * imageAspectRatioValues.value.height) / imageAspectRatioValues.value.width;

	return Math.floor(imageHeightValue);
});
const columnGapSize = computed(() => `${props.columnGap}px`);
const rowGapSize = computed(() => {
	const rowGap = props.rowGap ?? props.columnGap;

	return `${rowGap}px`;
});
const defaultCategory = computed(() => ({
	id: '',
	title: props.translations.allProducts || 'All products',
}));
const currentCategoryTitle = computed(() => {
	const category = props.categories?.find(({ id }) => id === props.productCategoryId);

	return category?.title || defaultCategory.value.title;
});
const computedStyles = computed(() => objectToCssVariablesDeprecated(props.textColorVars) as StyleValue);
const productCountText = computed(() => (props.totalProductCount > 1
	? `${props.totalProductCount} ${props.translations.products || 'products'}`
	: `${props.totalProductCount} ${props.translations.product || 'product'}`));
const isSingleColumnCentered = computed(() => props.isListCentered && props.products.length === 1);
const isSortingShown = computed(() => props.productSorting.enabled && props.productSorting.sortingOptions?.length);
const isProductSearchEnabled = computed(() => props.productSearch.isEnabled);
const computedProductSearchStyles = computed(() => ({
	fillColor: props.productSearch.fillColor || 'rgb(255, 255, 255)',
	textAndIconColor: props.productSearch.textAndIconColor || 'rgba(0, 0, 0)',
	borderColor: props.productSearch.borderColor || 'rgb(218, 220, 224)',
	borderRadius: props.productSearch.borderRadius || 4,
	inputAlignment: props.productSearch.inputAlignment || 'center',
	width: `${props.productSearch.width || 250}px`,
	resultItemHoverColor: props.productSearch.resultItemHoverColor || 'rgba(0, 0, 0, 0.06)',
}));
const isSkeletonLoaderShown = computed(() => (props.scrollBehaviour !== EcommerceProductListScrollBehaviour.PAGINATION
	? props.isLoading && props.currentPage === 1
	: props.isLoading));
const productListHeight = computed(() => {
	if (!productListHeightRef.value) {
		return 'auto';
	}

	return `${productListHeightRef.value}px`;
});
const searchNoResultText = computed(() => props.productSearch.noResultsText || props.translations?.searchNoResultsText || 'No results found');
const searchPlaceholderText = computed(() => props.productSearch.placeholderText || props.translations?.searchPlaceholderText || 'Search products');

const scrollToTopTriggerProductIndex = computed(() => getScrollToTopTriggerProductIndex(columnCountValue.value));

const getItemProductPageUrl = (product: EcommerceProduct) => {
	if (props.isProductListItemLinkDisabled) {
		// eslint-disable-next-line unicorn/no-useless-undefined
		return undefined;
	}

	const productSlug = product.seo_settings?.slug;

	if (!productSlug) {
		return window.location.href;
	}

	return `${window.location.origin}/${productSlug}`;
};

const getSmallestProductPrice = (product: EcommerceProduct): EcommerceVariantPrice => (
	isProductPriceRangeShown(product) ? getLowestPriceVariant(product).prices[0] : product.variants[0].prices[0]
);

const getProductImage = (product: EcommerceProduct): string | null => {
	if (!isProductPriceRangeShown(product)) {
		return product.thumbnail;
	}

	return getLowestPriceVariant(product).image_url || product.thumbnail;
};

const getSecondaryProductImage = (product: EcommerceProduct): EcommerceProductImage => (
	product.images?.[1] || product.media?.[1] || {} as EcommerceProductImage
);

const observeList = () => {
	if (blockProductListRef.value) {
		resizeObserver.value.observe(blockProductListRef.value);
	}
};

const getProductVariantId = (product: EcommerceProduct) => (product.type.value === EcommerceProductType.BOOKING
	? product.variants[0].id
	: (product.variants.find(({ id }: { id: string }) => {
		const variantQuantity = props.variantsQuantity.find(((v) => v.id === id));

		return variantQuantity && variantQuantity.inventory_quantity > 0;
	})?.id || product.variants[0].id));

const addBlockResizeObserver = () => {
	resizeObserver.value = new ResizeObserver((entries) => {
		const { width } = entries[0].contentRect;

		blockListWidth.value = width;
	});

	if (props.isFullWidth) {
		observeList();
	}
};

const handleScrollToTop = () => {
	blockProductListRef.value?.scrollIntoView({
		behavior: 'smooth',
		block: 'start',
	});
};

const handlePageChange = (page: number) => {
	emit('page-changed', page);

	isScrollToTopVisible.value = false;
};

watch(() => props.isFullWidth, (newValue) => {
	if (newValue) {
		observeList();
	} else {
		resizeObserver.value?.disconnect();
	}
});

onMounted(() => {
	addBlockResizeObserver();
});
</script>

<template>
	<div
		:id="blockId"
		ref="blockProductListRef"
		:style="computedStyles"
		class="block-product-list"
		:class="{ 'block-product-list--with-categories': isCategoryListEnabled }"
	>
		<ScrollToTop
			v-if="isScrollToTopEnabled"
			:is-whats-app-enabled="isWhatsAppEnabled"
			:scroll-to-top-style="scrollToTopStyle"
			class="block-product-list__scroll-to-top"
			:class="{ 'block-product-list__scroll-to-top--hidden': !isScrollToTopVisible }"
			@scroll-to-top="handleScrollToTop"
		/>
		<div
			class="block-product-list__wrapper"
			:class="{ 'block-product-list__wrapper--full-width': isFullWidth }"
		>
			<CategoryList
				v-if="isCategoriesLoaded && isCategoryListEnabled"
				v-qa="'category-list'"
				class="category-list"
				:translations="translations"
				:categories="categories"
				:current-category-id="productCategoryId"
				:is-mobile-view="isMobileView"
				:default-category="defaultCategory"
				:is-link-disabled="isCategoryItemLinkDisabled"
				:block-id="blockId"
				@category-click="emit('category-click', $event)"
			>
				<template #mobile>
					<ProductSortDropdown
						v-if="!isTotalProductCountShown && isSortingShown"
						:product-sorting="productSorting"
						:sorting="sorting"
						:translations="translations"
						class="product-list__sort"
						@sort-changed="emit('sort-changed', $event)"
					/>
				</template>
			</CategoryList>
			<div
				ref="productListContentContainerRef"
				class="block-product-list__content-container"
			>
				<h3
					v-if="isCategoryListEnabled && isCategoriesLoaded"
					class="block-product-list__category"
				>
					{{ currentCategoryTitle }}
				</h3>
				<ListSkeletonLoader
					v-if="isSkeletonLoaderShown"
					:column-count="columnCountValue"
					:image-ratio="imageRatio"
					:is-category-list-enabled="isCategoryListEnabled && !isCategoriesLoaded"
					:is-full-width="isFullWidth"
				/>
				<div
					v-else-if="isProductListShown"
					style="width: 100%;"
				>
					<div class="product-list">
						<div
							v-if="isTotalProductCountShown || isSortingShown || isProductSearchEnabled"
							class="product-list__header"
						>
							<div
								v-if="isProductSearchEnabled"
								class="product-list__header-item"
							>
								<SiteElementSearchBar
									class="product-list__search"
									:class="`product-list__search--${computedProductSearchStyles.inputAlignment}`"
									:placeholder-text="searchPlaceholderText"
									:no-results-text="searchNoResultText"
									:fill-color="computedProductSearchStyles.fillColor"
									:fill-color-hover="computedProductSearchStyles.fillColor"
									:text-and-icon-color="computedProductSearchStyles.textAndIconColor"
									:text-and-icon-color-hover="computedProductSearchStyles.textAndIconColor"
									:result-item-hover-color="computedProductSearchStyles.resultItemHoverColor"
									font-family="var(--font-primary)"
									:border-color="computedProductSearchStyles.borderColor"
									:border-color-hover="computedProductSearchStyles.borderColor"
									:border-radius="computedProductSearchStyles.borderRadius"
									:translations="props.translations"
									:site-id="siteId"
									:results="searchResults"
									:is-loading="isSearchResultsLoading"
									:search-term="props.searchTerm"
									@is-dropdown-open="emit('is-dropdown-open', $event)"
									@update:search-term="emit('update:search-term', $event)"
								/>
							</div>
							<div class="product-list__header-item">
								<p
									v-if="isTotalProductCountShown"
									class="product-list__total-count"
								>
									{{ productCountText }}
								</p>
								<ProductSortDropdown
									v-if="isSortingShown"
									v-qa="'product-sort-dropdown'"
									:product-sorting="productSorting"
									:sorting="sorting"
									:translations="translations"
									class="product-list__sort"
									:class="{ 'product-list__sort--hidden-mobile': !isTotalProductCountShown && isCategoryListEnabled }"
									@sort-changed="emit('sort-changed', $event)"
								/>
							</div>
						</div>
						<div
							class="product-list__content"
							:class="{ 'product-list__content--single-column': isSingleColumnCentered }"
						>
							<a
								v-for="(product, index) in products"
								:key="`product-${index}-${product.id}`"
								:href="getItemProductPageUrl(product)"
								class="product-list__link"
							>
								<ScrollToTopThresholdMarker
									v-if="isScrollToTopEnabled && scrollToTopTriggerProductIndex === index"
									:is-preview-mode="isPreviewMode"
									:is-in-editor="isInEditor"
									:margin-of-error="imageHeight"
									@update-visibility="isScrollToTopVisible = $event"
								/>
								<ProductListItem
									v-bind="customAttributes"
									:id="product.id"
									v-qa="'product-list-item'"
									:products="products"
									:image-url="getProductImage(product)"
									:secondary-image="getSecondaryProductImage(product)"
									:title="product.title"
									:ribbon="product.ribbon_text"
									:price="getSmallestProductPrice(product)"
									:text-align="textAlign"
									:is-eager="isEager && index === 0"
									:duration="getFormattedBookingDuration(product, translations)"
									:image-width="imageWidth"
									:image-height="imageHeight"
									:image-ratio="imageRatio"
									:image-hover-effect="imageHoverEffect"
									:image-border-radius="blockStyle?.imageBorderRadius"
									:is-store-quantity-tracked="product.variants[0].manage_inventory"
									:is-price-range-shown="isProductPriceRangeShown(product)"
									:quantity="getFullProductQuantity({
										product,
										variantsQuantity
									})"
									:product-type="product.type.value"
									:translations="translations"
									:is-mobile-view="isMobileView"
									:is-button-enabled="isButtonEnabled"
									:button-display="buttonDisplay"
									:button-text="buttonText"
									:button-style="buttonStyle"
									:button-type="buttonType"
									:button-border-width="buttonBorderWidth"
									:ribbon-style="ribbonStyle"
									:is-purchasable="product.purchasable ?? true"
									:is-button-full-width="isButtonFullWidth"
									:site-id="siteId"
									:is-cart-visible="isCartVisible"
									:is-add-to-cart-disabled="!canAddToCart(product, getProductVariantId(product))"
									@click="$emit('product-click', product)"
									@button-click="$emit('button-click', product)"
								/>
							</a>
						</div>
						<ProductListPagination
							:block-id="blockId"
							:is-loading="isLoading"
							:scroll-behaviour="scrollBehaviour"
							:current-page="currentPage"
							:block-background-color="backgroundColor"
							:page-count="pageCount"
							@set-is-animation-active="isAnimationActive = $event"
							@page-changed="handlePageChange"
						/>
					</div>
				</div>
				<div
					v-else
					class="block-product-list__empty-state"
				>
					<ProductListEmptyState
						v-qa="'product-list-empty-state'"
						:text-color-vars="textColorVars"
						:empty-page-message="emptyPageMessage"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "@zyro-inc/site-modules/scss/mixins/font-style";
@include font-style("h6", "h2", ".block-product-list");
@include font-style("h3", "h3", ".block-product-list");
@include font-style("body", ".text-body", ".block-product-list");
@include font-style("body-small", ".text-body-small", ".block-product-list");

@mixin product-list-mobile() {
	.block-product-list {
		padding: var(--m-block-padding);

		&__wrapper {
			flex-direction: column;
			gap: 0;
		}

		&__category {
			display: none;
		}
	}

	.product-list {
		position: relative;

		&__content,
		&__content--single-column {
			display: grid;
			max-width: unset;
			margin: unset;
		}

		&__sort {
			display: flex;
			justify-content: space-between;
			width: auto;
			flex-grow: 1;

			&--hidden-mobile {
				display: none;
			}
		}

		&__search {
			width: 100%;
			margin-top: 16px;
		}

		&__header {
			margin-bottom: 16px;
			gap: 8px;
		}

		&__header-item {
			flex-wrap: wrap;
		}

		&__total-count {
			color: var(--body-m-color, var(--body-color));
			flex-grow: 0;
			margin-right: 4px;
			font-size: 14px;
		}
	}
}

.block-product-list {
	z-index: $z-index-site-engine-block-grid;
	padding: var(--block-padding);

	&--with-categories {
		.product-list-container {
			justify-content: flex-start;
		}
	}

	&__wrapper {
		position: relative;
		max-width: var(--content-width);
		display: flex;
		width: 100%;
		margin: 0 auto;
		gap: 40px;

		&--full-width {
			max-width: unset;
		}
	}

	&__content-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	&__category {
		margin-bottom: 32px;
		word-break: break-all;
	}

	&__empty-state {
		display: flex;
		flex-direction: column;
		width: 100%;
	}

	&__scroll-to-top {
		visibility: visible;
		transition: opacity 0.2s ease-in-out;
		opacity: 1;

		&--hidden {
			visibility: hidden;
			opacity: 0;
		}
	}
}

.category-list {
	max-height: v-bind(productListHeight);
}

.product-list {
	$this: &;

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;

	&__header {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	&__header-item {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		margin-bottom: 24px;
	}

	&__search {
		width: v-bind('computedProductSearchStyles.width');

		&--right {
			margin-left: auto;
		}

		&--left {
			margin-right: auto;
		}

		&--center {
			margin: 0 auto;
		}
	}

	&__total-count {
		flex: 1 0 auto;
		color: var(--body-color);
		font-size: 14px;

		& ~ #{$this}__sort {
			justify-content: flex-end;
		}
	}

	&__link {
		width: 100%;
		text-decoration: none;
	}

	&__content {
		display: grid;
		grid-template-columns: repeat(v-bind(columnCountValue), 1fr);
		column-gap: v-bind(columnGapSize);
		width: 100%;
		row-gap: v-bind(rowGapSize);

		&--single-column {
			display: flex;
			max-width: calc(v-bind(imageWidth) * 1px);
			margin: 0 auto;
		}
	}
}

@include site-engine-mobile {
	@include product-list-mobile;
}

@media screen and (min-width: 601px) and (max-width: $media-mobile) {
	@include product-list-mobile;

	.category-list {
		:deep(.category-list__slot) {
			width: fit-content;
		}
	}

	.product-list {
		&__content,
		&__content--single-column {
			grid-template-columns: repeat(2, 1fr);
		}

		&__sort {
			justify-content: flex-end;
		}
	}
}
</style>
